
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Wysiwyg',
  props: {
    data: Object,
  } as any, // eslint-disable-line
  setup(props) {
    const template = `<div>${props.data.htmltext}</div>`
    const runtimeComponent = computed(() => ({
      template,
    }))

    return {
      runtimeComponent,
    }
  },
})
