<template>
  <component :is="runtimeComponent" class="wysiwyg"></component>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Wysiwyg',
  props: {
    data: Object,
  } as any, // eslint-disable-line
  setup(props) {
    const template = `<div>${props.data.htmltext}</div>`
    const runtimeComponent = computed(() => ({
      template,
    }))

    return {
      runtimeComponent,
    }
  },
})
</script>

<style lang="scss">
.wysiwyg {
  @include owl;

  > * + * {
    margin-top: $spacing / 2;
  }
}

.wysiwyg iframe {
  width: 100%;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3 {
  margin-top: $spacing * 1.8;
}

.wysiwyg h4,
.wysiwyg h5 {
  margin-top: $spacing * 1.5;
}

.wysiwyg ul {
  list-style: none;
  padding: 0;
  margin: $spacing * 1.5 0;
}

.wysiwyg li {
  padding-left: 16px;

  & + li {
    margin-top: $spacing / 2;
  }

  ul {
    margin-top: 1rem;
    margin-left: 2rem;
  }
}

.wysiwyg li::before {
  content: '\2022';
  color: $tangerine;
  display: inline-block;
  width: 6px;
  margin-left: -16px;
  margin-right: $spacing / 2;
}

.wysiwyg button {
  width: auto;
}

.wysiwyg img {
  margin-bottom: $spacing / 2;
}

.wysiwyg a {
  color: $regal-blue;
  text-decoration: underline;
}
</style>
